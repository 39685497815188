<template>
    <div class="main-container">
        <div class="navi-container">
            <el-tabs @tab-click="onNaviTabClick">
                <el-tab-pane label="查找检查" name="find">查找检查</el-tab-pane>
                <el-tab-pane label="最近浏览" name="recent">最近浏览</el-tab-pane>
                <el-tab-pane label="关心的人" name="patient">关心的人</el-tab-pane>
            </el-tabs>
        </div>
        <div class="content-container">
            <el-tabs @tab-click="onContentTabClick">
                <el-tab-pane v-for="tab in contentTabs" :key="tab.id" :label="tab.text" name="find">{{tab.text}}</el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Main',

    data() {
        return {
            contentTabs: []
        };
    }

}
</script>

<style scoped>
    .main-container {
        display:flex;
    }

</style>